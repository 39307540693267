import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <p> Designed by amtakethat. Created with the enormous help of ...</p>
            <p>All Love Rights Reserved &copy;</p>
        </footer>
    );
};

export default Footer;